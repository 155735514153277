import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import Typography from 'core/ui/Typography';
import { useTranslation } from 'react-i18next';
import { Collapse } from '@material-ui/core';
import { convertTimerToForm, listScores } from 'core/utils/helpers';
import ViewVideo from 'components/LibraryMedias/MediaFilesGallery/ViewVideo';
import LabelBreakLine from 'core/ui/LabelBreakLine';
import SectionTimerInfo from 'components/Workout/Form/Section/SectionTimerInfo';
import ViewSectionScoresTypes from 'components/Workout/Form/Section/ViewSectionScoresTypes';
import { primary, secondary, secondaryBg } from 'core/ui/Colors';
import SectionPersonalRecords from 'components/Workout/Show/Tabs/Overview/SectionPersonalRecords';
import MIcon from 'core/ui/MIcon';
import SectionMenu from './SectionMenu';

const SectionView = ({
  channel,
  section,
  calendarInfo,
  workouts,
  setModalSection,
  removeSection,
  cloneSection,
  moveSection,
  sectionOpened,
  setModalCloneDay,
  cType,
}) => {
  const { t } = useTranslation();
  const [opened, setOpened] = useState(false);

  useEffect(() => {
    setOpened(sectionOpened);
  }, [sectionOpened]);

  const sectionVideos = section?.mediaFilesAssociations
    ?.filter((x) => x.kind === 'section' && !!x.mediaFile)
    .map((x) => x.mediaFile);

  const editSection = () => {
    const data = {};
    data.section = {
      ...section,
      id: null,
      mediaFilesAssociations: section?.mediaFilesAssociations?.map((ass) => ({
        id: null,
        mediaFileId: ass.mediaFile?.id,
        mediaFile: ass.mediaFile,
        kind: ass.kind,
      })),
      mediaFilesAssociationsAttributes: section?.mediaFilesAssociations?.map((ass) => ({
        id: null,
        mediaFileId: ass.mediaFile?.id,
        mediaFile: ass.mediaFile,
        kind: ass.kind,
      })),
    };

    data.timerData = convertTimerToForm(section.timerData);

    setModalSection({
      open: true,
      workouts,
      currentSection: data,
    });
  };

  return (
    <Box mb={1}>
      <Box
        style={{ backgroundColor: secondary }}
        display="flex"
        className="link"
        alignItems="center"
        p={1}
      >
        <Box flexGrow={1} onClick={() => setOpened(!opened)}>
          <Typography color="secondary" variant="body2" style={{ color: 'white' }}>
            {section?.title?.toUpperCase()}
          </Typography>

          {section?.workout?.channelsWorkoutsType?.name && (
            <Typography style={{ fontSize: 10, color: primary }}>
              <MIcon icon="fitness_center" color="white" size={9} />
              &nbsp;{section?.workout?.channelsWorkoutsType?.name}
            </Typography>
          )}
          {section?.channelProgram && (
            <Typography style={{ fontSize: 10, color: primary }}>
              <MIcon icon="fitness_center" color="white" size={9} />
              &nbsp;{section?.channelProgram?.name}
            </Typography>
          )}
        </Box>
        <Box>
          <SectionMenu
            channel={channel}
            calendarInfo={calendarInfo}
            section={section}
            actions={{
              editSection,
              moveSection: (day) => moveSection(section, workouts, day, cType),

              cloneSection: (day) => cloneSection(section, day),
              removeSection: () => removeSection(section, workouts),
              setModalCloneDay,
            }}
          />
        </Box>
      </Box>

      <Box style={{ backgroundColor: secondaryBg }}>
        <Collapse in={opened}>
          <Box px={2}>
            {/* {sectionVideos?.length > 0 && (
              <Box my={3}>
                {sectionVideos.map((x) => (
                  <Box key={x.id} mb={1}>
                    <ViewVideo url={x?.url} />
                  </Box>
                ))}
              </Box>
            )} */}
            <Box px={2} pb={5}>
              <Typography
                mb={3}
                component="div"
                className="roboto-regular"
                style={{ color: 'white' }}
              >
                <LabelBreakLine>{section?.description}</LabelBreakLine>
              </Typography>

              <SectionPersonalRecords section={section} textColor="white" />

              {section?.timerData?.type !== 'none' && (
                <Box display="flex" alignItems="center">
                  <SectionTimerInfo fontColor="white" section={section} showTimer={false} />
                </Box>
              )}

              {listScores(section, t) && (
                <Box my={2}>
                  <ViewSectionScoresTypes
                    scoresFontColor="white"
                    buttonColor="white"
                    scores={section?.scores}
                  />
                </Box>
              )}
            </Box>
          </Box>
        </Collapse>
      </Box>
    </Box>
  );
};

export default SectionView;

SectionView.defaultProps = {
  section: {},
  index: 0,
  selectTab: null,
  hideSections: true,
  isRegistered: false,
};
