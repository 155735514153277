import React, { useEffect, useState } from 'react';
import Box from 'core/ui/Box';
import { date, dateAdd, formatDateToApi, timeZoneName } from 'core/utils/formatters/date';
import useActions from 'modules/map/useActions';
import Modules from 'modules';
import LoaderSm from 'core/ui/LoaderSm';
import { clearSectionIds, convertTimerToApi, reorder } from 'core/utils/helpers';
import notifications from 'modules/notifications';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { ProgramViewContext } from 'contexts';
import { DragDropContext } from 'react-beautiful-dnd';
import { ButtonPrimary } from 'core/ui/Button';
import { AppBar, Link, makeStyles } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowRight } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import { secondaryXLight } from 'core/ui/Colors';
import useSelectors from 'modules/map/useSelectors';
import { channelWorkoutsProgramsModule } from 'modules/channelWorkoutsPrograms';
import WeekView from './WeekView';
import ModalSection from './ModalSection';
import ModalCloneDay from './ModalCloneDay';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1,
  },
  cardDay: {
    backgroundColor: secondaryXLight,
    padding: 4,
  },
}));
const WeekProgramCalendarView = ({ channel, close, calendarInfo }) => {
  const classes = useStyles();
  const [sectionOpened, setSectionOpened] = useState({});
  const loadingView = useSelectors(channelWorkoutsProgramsModule, 'loadingView');
  const { request } = useActions();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const buttonColor = channel?.customColors?.buttonColor;

  const [modalSection, setModalSection] = useState({});
  const [modalCloneDay, setModalCloneDay] = useState();
  const [weekView, setWeekView] = useState();
  const [loading, setLoading] = useState(false);

  const getWorkouts = () => {
    setLoading(true);

    request({
      action: Modules.channelWorkoutsPrograms.actions.getProgramView,
      data: {
        channelId: channel?.id,
        startAt: formatDateToApi(calendarInfo.start),
        endAt: formatDateToApi(calendarInfo.end),
        timezone: timeZoneName(),
        view: 'program_view',
      },
      options: {
        onSuccess: (data) => {
          setLoading(false);
          setWeekView(data);
        },
        onError: () => {
          setLoading(false);
        },
      },
    });
  };

  useEffect(() => {
    if (calendarInfo?.start) {
      getWorkouts();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [calendarInfo?.start]);

  const saveSection = (data, workouts = null) => {
    request({
      action: Modules.channelWorkoutsPrograms.actions.saveSectionData,
      data: {
        channelId: channel?.id,
        sectionData: { id: null, ...data },
        workoutsIds: (workouts || modalSection?.workouts)?.map((x) => x.id),
      },
      options: {
        onSuccess: () => {
          getWorkouts();
        },
      },
    });
  };

  const saveOrderSection = (orderedSections, workouts = null) => {
    request({
      action: Modules.channelWorkoutsPrograms.actions.saveOrderSections,
      data: {
        channelId: channel?.id,
        sections: orderedSections.map((x, idx) => ({
          title: x?.title,
          slug: x?.slug,
          order: idx,
        })),
        workoutsIds: (workouts || modalSection?.workouts)?.map((x) => x.id),
      },
      options: {
        onSuccess: () => {
          getWorkouts();
        },
      },
    });
  };

  const removeSection = (section, workouts) => {
    request({
      action: Modules.channelWorkoutsPrograms.actions.removeSection,
      data: {
        channelId: channel?.id,
        sectionSlug: section?.slug,
        workoutsIds: workouts?.map((x) => x.id),
      },
      options: {
        onSuccess: () => {
          getWorkouts();
        },
      },
    });
  };

  const formatObjectSection = (section) => {
    if (!section?.timerData?.work?.hours) {
      return clearSectionIds(section);
    }

    const { work, rest, timeStart, customIntervalTimers } = convertTimerToApi({
      timerData: section?.timerData,
      isVideoClass: false,
    });

    return {
      ...clearSectionIds(section),
      timerData: {
        ...section?.timerData,
        customIntervalTimers,
        work,
        rest,
        timeStart,
        count: section?.timerData?.count || 'UP',
      },
    };
  };

  const moveSection = (section, workouts, day, classTypeDest) => {
    const dayData = weekView ? weekView[day] : {};

    const classTypeData =
      dayData?.classesTypes[classTypeDest || section?.workout?.channelsWorkoutsType?.id];

    if (!classTypeData?.workouts) {
      dispatch(notifications.warning(t('validation.workouts.day')));
      return;
    }

    const sec = formatObjectSection(section);

    request({
      action: Modules.channelWorkoutsPrograms.actions.moveSection,
      data: {
        channelId: channel?.id,
        sectionData: sec,
        workoutsIdsRemove: workouts?.map((x) => x.id),
        workoutsIdsAdd: classTypeData?.workouts?.map((x) => x.id),
      },
      options: {
        onSuccess: () => {
          getWorkouts();
        },
      },
    });
  };

  const cloneSection = (section, day) => {
    const dayData = weekView ? weekView[day] : {};

    const classTypeData = dayData?.classesTypes[section?.workout?.channelsWorkoutsType?.id];

    if (!classTypeData?.workouts) {
      dispatch(notifications.warning(t('validation.workouts.day')));
      return;
    }

    const sec = formatObjectSection(section);
    sec.slug = null;
    request({
      action: Modules.channelWorkoutsPrograms.actions.cloneSection,
      data: {
        channelId: channel?.id,
        sectionData: sec,
        workoutsIdsAdd: classTypeData?.workouts?.map((x) => x.id),
      },
      options: {
        onSuccess: () => {
          getWorkouts();
        },
      },
    });
  };

  const orderSectionDay = (classType, day, result) => {
    let newItems = [];
    setWeekView((prev) => {
      const newPrev = { ...prev };

      const newSections = {};
      const sections = newPrev[day]?.classesTypes[classType].sections;

      const arraySec = Object.keys(sections).map((x, idx) => ({
        key: x,
        title: sections[x]?.title,
        slug: sections[x]?.slug,
        index: idx,
      }));
      newItems = reorder(arraySec, result.source.index, result.destination.index);

      newItems.forEach((item) => {
        newSections[item?.key] = sections[item?.key];
      });

      newPrev[day].classesTypes[classType].sections = newSections;
      return newPrev;
    });

    return newItems;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const dataOrig = result?.draggableId?.split('-');
    const classTypeOrig = dataOrig[1];
    const dayOrig = dataOrig[2];
    const sectionSlugOrig = dataOrig[3];

    const dayData = weekView ? weekView[dayOrig] : {};

    const dataDest = result?.destination?.droppableId?.split('-');
    const classTypeDest = dataDest[1];
    const dayDest = dataDest[2];

    const classTypeDataOrig = dayData?.classesTypes[classTypeOrig];

    const section = classTypeDataOrig?.sections[sectionSlugOrig];

    // ORDER
    if (dayDest === dayOrig) {
      if (classTypeDest !== classTypeOrig) {
        moveSection(section, classTypeDataOrig.workouts, dayDest, classTypeDest);
      } else {
        const orderedItems = orderSectionDay(classTypeDest, dayDest, result);
        saveOrderSection(orderedItems, classTypeDataOrig?.workouts);
      }

      return;
    }

    if (dayDest !== dayOrig) {
      moveSection(section, classTypeDataOrig.workouts, dayDest, classTypeDest);
      setWeekView((prev) => {
        const newPrev = { ...prev };
        delete newPrev[dayOrig]?.classesTypes[classTypeOrig].sections[sectionSlugOrig];
        newPrev[dayDest].classesTypes[classTypeDest].sections[sectionSlugOrig] = section;
        return newPrev;
      });
      return;
    }

    dispatch(notifications.warning(t('validation.programming.classType')));
  };

  return (
    <>
      <AppBar className={classes.appBar} color="secondary">
        <Box className="grid-calendar-week" style={{ flex: 1 }}>
          {[...new Array(7)].map((indexDay, index) => (
            <Box className={classes.cardDay} key={index.toString()}>
              <Box display="flex" alignItems="center">
                <Box flexGrow={1}>
                  <Typography align="center" color="secondary">
                    {date(
                      dateAdd(calendarInfo?.start, index, 'days'),
                      channel?.useUsDateFormat ? 'ddd M/DD' : 'ddd DD/M',
                    )}
                  </Typography>
                </Box>

                {loadingView ? (
                  <LoaderSm loading />
                ) : (
                  <div>
                    {sectionOpened[index] ? (
                      <Link
                        color="secondary"
                        onClick={() => setSectionOpened((prev) => ({ ...prev, [index]: false }))}
                      >
                        <KeyboardArrowDown />
                      </Link>
                    ) : (
                      <Link
                        color="secondary"
                        onClick={() => setSectionOpened((prev) => ({ ...prev, [index]: true }))}
                      >
                        <KeyboardArrowRight />
                      </Link>
                    )}
                  </div>
                )}
              </Box>
            </Box>
          ))}
        </Box>
      </AppBar>

      <>
        <ModalSection
          modalSection={modalSection}
          setModalSection={setModalSection}
          channel={channel}
          callbackSave={saveSection}
        />

        <ModalCloneDay
          open={modalCloneDay?.open}
          day={modalCloneDay?.day}
          section={modalCloneDay?.section}
          close={() => setModalCloneDay({ open: false })}
          channel={channel}
          callback={() => {
            setModalCloneDay({ open: false });
            getWorkouts();
          }}
        />

        <ProgramViewContext.Provider
          value={{
            sectionOpened,
            weekView,
            buttonColor,
            calendarInfo,
            channel,
            cloneSection,
            moveSection,
            saveSection,
            setModalSection,
            removeSection,
            setModalCloneDay,
          }}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <WeekView />
          </DragDropContext>
          <Box display="flex" justifyContent="center">
            <LoaderSm loading={loading} />
          </Box>
          <Box display="flex" justifyContent="flex-end" m={3}>
            <ButtonPrimary onClick={close}>{t('button.close')}</ButtonPrimary>
          </Box>
        </ProgramViewContext.Provider>
      </>
    </>
  );
};

WeekProgramCalendarView.propTypes = {};

WeekProgramCalendarView.defaultProps = {
  close: () => {},
};

export default WeekProgramCalendarView;
