import React, { useContext, useMemo } from 'react';
import Box from 'core/ui/Box';
import Button, { ButtonPrimary } from 'core/ui/Button';
import { Add } from '@material-ui/icons';
import Typography from 'core/ui/Typography';
import MIcon from 'core/ui/MIcon';
import { primary, secondary } from 'core/ui/Colors';
import { Droppable, Draggable } from 'react-beautiful-dnd';
import { ProgramViewContext } from 'contexts';
import usePagination from 'core/useArrayPagination';
import { useTranslation } from 'react-i18next';
import SectionView from './SectionView';

const WeekDayViewSection = ({
  classTypeData,
  cType,
  day,
  sectionOpened,
  index,
  setModalClasses,
}) => {
  const { t } = useTranslation();
  const {
    channel,
    buttonColor,
    calendarInfo,
    removeSection,
    cloneSection,
    moveSection,
    setModalSection,
    setModalCloneDay,
  } = useContext(ProgramViewContext);

  const allSections = useMemo(() => Object.keys(classTypeData?.sections || {}), [classTypeData]);

  const { items, nextPage, totalPages, totalItems } = usePagination(allSections, 1, 3);

  return (
    <Box>
      <Droppable droppableId={`drop-${cType}-${day}`}>
        {(provided) => (
          <div {...provided.droppableProps} style={{ minHeight: 10 }} ref={provided.innerRef}>
            {items.map((sectionKeySlug, indexSection) => {
              const sectionData = classTypeData?.sections[sectionKeySlug];

              return (
                <Draggable
                  key={`drag-${cType}-${day}-${sectionKeySlug}`}
                  draggableId={`drag-${cType}-${day}-${sectionKeySlug}`}
                  index={indexSection}
                >
                  {(dragProv) => (
                    <div
                      ref={dragProv.innerRef}
                      {...dragProv.draggableProps}
                      {...dragProv.dragHandleProps}
                    >
                      <SectionView
                        channel={channel}
                        setModalSection={setModalSection}
                        buttonColor={buttonColor}
                        workout={{}}
                        removeSection={removeSection}
                        cloneSection={cloneSection}
                        setModalCloneDay={setModalCloneDay}
                        moveSection={moveSection}
                        calendarInfo={calendarInfo}
                        section={sectionData}
                        workouts={classTypeData?.workouts}
                        sectionOpened={sectionOpened[index]}
                        cType={cType}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}

            {totalPages > 0 && totalItems > items.length && (
              <div className="tw-flex tw-items-center tw-justify-center">
                <Button onClick={() => nextPage()}>
                  {t('button.loadMore')}&nbsp;-&nbsp;
                  {items.length}/{totalItems}
                </Button>
              </div>
            )}
          </div>
        )}
      </Droppable>

      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={3}>
        {classTypeData?.type && classTypeData?.type?.name && (
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            className="hover"
            onClick={() => setModalClasses({ open: true, workouts: classTypeData?.workouts })}
          >
            <MIcon icon="fitness_center" size={14} />
            <Typography style={{ fontSize: 14, color: secondary }}>
              &nbsp;{classTypeData?.type?.name}&nbsp;
            </Typography>

            <MIcon icon="timer" size={14} color={primary} />
          </Box>
        )}

        <ButtonPrimary
          onClick={() => setModalSection({ open: true, workouts: classTypeData?.workouts })}
        >
          <Add style={{ color: 'white' }} />
        </ButtonPrimary>
      </Box>
    </Box>
  );
};

WeekDayViewSection.propTypes = {};

WeekDayViewSection.defaultProps = {
  close: () => {},
};

export default WeekDayViewSection;
